<template>
    <div class="news-container app" style="padding: 20px;">
        <van-skeleton
                :row="20"
                :loading="loading">
            <h5>活动名称: <span>{{showInfo.preferentialName}}</span></h5>
            <h5>开始时间: <span>{{showInfo.startTime}}</span></h5>
            <h5>结束时间: <span>{{showInfo.endTime}}</span></h5>

            <div  v-if="showInfo" style="white-space:pre-wrap; margin-top: 50px;" v-html="showInfo.preferentialContent">  </div>
        </van-skeleton>

    </div>
</template>

<script>
    import { discountDetailsById  } from '@/api/discountInfo'
    export default {
        name: "index",
        data(){
            return{
                loading:false,
                showInfo:{}
            }
        },
        created() {
            this.id = parseInt(this.$route.query.id)
            if(this.id){
                this.onLoad()
            }else{
                this.$failToast('参数错误')
            }
        },
        methods:{
            onLoad(){
                this.loading = true
                discountDetailsById(
                    this.id
                ).then(res=>{
                    console.log('res.data', res.data);
                    this.showInfo = res.data
                    this.loading = false
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    @media screen and (min-width: 800px) {
        .news-container {
            box-sizing: border-box;
            padding: 30px;
            width: 100% !important;
            background-color: #f9f9f9 !important;
            /*margin-top:500px;*/
            position: absolute;
            top:0;
            left: 50%;
            transform: translate(-50%);
            max-width: 1200px;
        }
    }
        .app{
            padding: 10px;
            word-break: break-word;
        }
</style>
